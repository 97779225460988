@tailwind base;
.hide-scrollbar::-webkit-scrollbar {
  display: none;
}
.hide-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Poppins";
  src: url("./assets/font/Poppins/Poppins-Light.ttf");
  font-weight: 300;
}
@font-face {
  font-family: "Poppins";
  src: url("./assets/font/Poppins/Poppins-Regular.ttf");
  font-weight: normal;
}
@font-face {
  font-family: "Poppins";
  src: url("./assets/font/Poppins/Poppins-Medium.ttf");
  font-weight: 500;
}
@font-face {
  font-family: "Poppins";
  src: url("./assets/font/Poppins/Poppins-SemiBold.ttf");
  font-weight: 600;
}
@font-face {
  font-family: "Poppins";
  src: url("./assets/font/Poppins/Poppins-Bold.ttf");
  font-weight: 700;
}
@font-face {
  font-family: "Poppins";
  src: url("./assets/font/Poppins/Poppins-ExtraBold.ttf");
  font-weight: 800;
}

* {
  font-family: "Poppins";
}

.shadow-top-right {
  box-shadow: 4px -4px 4px 0px rgba(0, 0, 0, 0.25);
  -webkit-box-shadow: 4px -4px 4px 0px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 4px -4px 4px 0px rgba(0, 0, 0, 0.25);
}

.shadow-bottom-left {
  box-shadow: -4px 4px 4px 0px rgba(0, 0, 0, 0.25);
  -webkit-box-shadow: -4px 4px 4px 0px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: -4px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

html,
body,
#root {
  height: 100%;
}
.jodit-status-bar-link {
  display: none;
}
